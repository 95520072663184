<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Invite User</h2>
    </template>
    <template #action-bar>
      <div class="level">
        <div class="level-left">
          <b-button
            type="is-text has-text-primary"
            tag="router-link"
            icon-left="plus"
            label="Add New User"
            to="/setting/user/create"
          />
        </div>
        <div class="level-right">
          <b-button icon-left="filter-variant" class="is-command">
            Filter
          </b-button>
          <b-input icon-right="magnify" rounded v-model="search">
            Search
          </b-input>
        </div>
      </div>
    </template>
    <template #page-content>
      <r-card>
        <b-table
          :data="data ? data.data : []"
          aria-next-label="Next page"
          :loading="isLoading"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          backend-sorting
          @sort="onSort"
          :current-page="page"
          hoverable
        >
          <b-table-column field="no" label="No" v-slot="props">
            {{ props.index + 1 }}
          </b-table-column>
          <b-table-column field="email" sortable label="Email" v-slot="props">
            {{ props.row.user.email }}
          </b-table-column>
          <b-table-column field="role" sortable label="Role" v-slot="props">
            {{ props.row.role }}
          </b-table-column>

          <b-table-column field="action" label="Action" v-slot="props">
            <div class="columns" v-if="props.row.role != 'Owner'">
              <div class="column is-2" @click="updateUser(props.row.id)">
                <b-icon
                  type="is-primary"
                  icon="pencil"
                  style="cursor: pointer"
                ></b-icon>
              </div>
              <div class="column is-2" @click="deleteUser(props.row.id)">
                <b-icon
                  type="is-primary"
                  icon="delete"
                  style="cursor: pointer"
                ></b-icon>
              </div>
            </div>
          </b-table-column>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  No data yet, please contact us if you have any problems
                  (hello@getrise.id)
                </p>
              </div>
            </section>
          </template>
        </b-table>
      </r-card>
      <router-view />
    </template>
  </r-page>
</template>
<script>
export default {
  data() {
    return {
      data: {
        meta: {},
        data: [],
      },
      perPage: 10,
      sortField: 'created_at',
      sortOrder: 'desc',
      total: 0,
      page: 1,
      lastPage: 1,
      search: '',
      isLoading: false,
      delay: (function () {
        let timer = 0
        return function (callback, ms) {
          clearTimeout(timer)
          timer = setTimeout(callback, ms)
        }
      })(),
    }
  },
  created() {
    this.isLoading = true
    this.fetchData(
      this.page,
      this.perPage,
      this.sortField,
      this.sortOrder,
      this.search
    )
    document.addEventListener('scroll', this.infiniteScroll)
  },

  computed: {},
  destroyed() {
    document.removeEventListener('scroll', this.infiniteScroll)
  },
  methods: {
    async fetchData(page, perPage, orderBy, orderDirection, search) {
      await this.$store
        .dispatch('user/getUsers', {
          page,
          perPage,
          orderBy,
          orderDirection,
          search,
        })
        .then((response) => {
          response.data.data.forEach((item) => {
            this.data.data.push(item)
          })
          this.lastPage = response.data.meta.lastPage
        })
        .catch((error) => {
          console.log(error)
        })

      this.isLoading = false
    },
    deleteUser(id) {
      this.$swal({
        icon: 'warning',
        title: 'Do you want to delete this User?',
        showCancelButton: true,
        confirmButtonText: `Yes`,
        cancelButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch('user/deleteUser', { id }).then(
            () => {
              this.$swal({
                icon: 'success',
                title: 'User has been deleted!',
              })
              this.data.data = []
              this.page = 1
              this.isLoading = true
              this.fetchData(
                this.page,
                this.perPage,
                this.sortField,
                this.sortOrder,
                this.search
              )
            },
            () => {
              this.$swal({
                icon: 'error',
                title: 'User failed to deleted!',
              })
            }
          )
        }
      })
    },
    onSort(field, order) {
      this.data.data = []
      this.page = 1
      this.sortField = field
      this.sortOrder = order
      this.isLoading = true
      this.fetchData(
        this.page,
        this.perPage,
        this.sortField,
        this.sortOrder,
        this.search
      )
    },
    updateUser(id) {
      console.log(id)
      this.$router.push({
        name: 'update-setting-user',
        params: {
          id: id,
        },
      })
    },
    infiniteScroll() {
      this.delay(() => {
        if (this.page < this.lastPage) {
          if (
            window.innerHeight + window.scrollY >=
            document.documentElement.scrollHeight - 20
          ) {
            this.page = this.page + 1
            this.isLoading = true
            this.fetchData(
              this.page,
              this.perPage,
              this.sortField,
              this.sortOrder,
              this.search
            )
          }
        }
      }, 1000)
    },
  },
  watch: {
    search(input) {
      this.data.data = []
      this.search = input
      this.page = 1
      this.isLoading = true
      this.delay(() => {
        this.fetchData(
          this.page,
          this.perPage,
          this.sortField,
          this.sortOrder,
          this.search
        )
      }, 300)
    },
  },
}
</script>
